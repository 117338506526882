import { css } from "@emotion/react";
import tw from "twin.macro";

export const getStyles = ({ isFeaturedCompany, companyIndex }) => ({
	featuredCompanyHeader: {
		wrapper:
			"tw-py-2 tw-px-2 tw-flex tw-justify-center tw-bg-[#359492] tw-text-white tw-rounded-t-lg",
		contentWrapper:
			"tw-text-center tw-text-base tw-font-bold tw-leading-6 tw-tracking-[-0.16px]",
	},
	serviceFlag: {
		wrapper: "",
		flag: `tw-absolute tw-top-4 tw-left-4 tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center tw-rounded-full lg:tw-w-8 lg:tw-h-8 lg:tw-top-2/4 lg:tw--translate-y-2/4 xl:tw-left-6 ${
			companyIndex === 1
				? "tw-bg-[#616096] tw-text-white"
				: "tw-bg-[#EDEDEE] tw-text-[#22293A]"
		}`,
		number: `tw-text-current tw-text-sm tw-font-medium tw-leading-normal lg:tw-text-base lg:tw-leading-6 lg:tw-top-6`,
		contentWrapper:
			"tw-absolute tw-top-4 tw-left-12 tw-py-1 tw-pl-2 tw-pr-4 tw-flex tw-items-center tw-gap-1 tw-bg-[#2A7675] tw-text-white lg:tw-gap-2 [clip-path:polygon(100%_0,calc(100%_-_10px)_50%,100%_100%,0_100%,0_0)] lg:tw-left-0",
		icon: "tw-inline-flex",
		text: "tw-text-xs tw-font-semibold tw-leading-4",
	},
	companyLogo: { picture: "", image: "tw-w-[132px] sm:tw-w-[160px] tw-h-full" },
	rating: {
		wrapper:
			"tw-w-fit tw-inline-flex tw-items-center tw-gap-1.5 tw-rounded-full tw-bg-white tw-py-2.5 tw-px-4 tw-shadow-[0px_4px_24px_0px_rgba(23,23,23,0.08),0px_0px_1px_0px_rgba(23,23,23,0.16)] lg:tw-py-2 lg:tw-pr-4 lg:tw-pl-3.5",
		score:
			"tw-text-2xl tw-font-bold tw-leading-6 tw-tracking-[-0.96px] tw-text-[#22293A] lg:tw-text-[28px] lg:tw-leading-9 lg:tw-tracking-[-1.12px]",
		stars: "tw-flex tw-flex-col tw-gap-1",
		starColor: "!tw-text-[#359492] !tw-text-base lg:!tw-text-lg",
		count: "tw-ml-1 tw-text-xs tw-font-semibold tw-leading-3 tw-text-[#22293A]",
	},
	productDescription:
		"tw-text-pretty tw-text-lg tw-font-bold tw-leading-6 tw-tracking-[-0.18px] tw-text-[#131D2B] lg:tw-text-xl lg:tw-leading-7 lg:tw-tracking-[-0.2px]",
	coupon: {
		wrapper: `tw-mt-4 tw-overflow-hidden tw-rounded-lg tw-flex lg:tw-max-w-fit ${companyIndex === 1 ? "tw-bg-[#FDEEF2]" : "tw-bg-[#F5F3FF]"}`,
		offerWrapper: `tw-w-min tw-pl-2 tw-pr-4 tw-flex tw-items-center tw-justify-center tw-text-center tw-text-white [clip-path:polygon(100%_0,calc(100%-8px)_100%,0_100%,0_0)] ${companyIndex === 1 ? "tw-bg-[#E1255B] tw-py-1.5" : "tw-bg-[#616096] tw-py-1"}`,
		offerIcon: `tw-inline-flex tw-items-center tw-mr-1.5 tw-shrink-0 tw-text-[26px] tw-text-white ${companyIndex === 1 ? " tw-text-[26px]" : "tw-text-2xl"}`,
		offerContent: `tw-font-bold tw-uppercase ${companyIndex === 1 ? "tw-text-[11px] tw-leading-[13px]" : "tw-text-[10px] tw-leading-3"}`,
		contentWrapper:
			"tw-flex-1 tw-inline-flex tw-items-center tw-justify-center tw-p-1.5 lg:tw-p-3",
		content: `tw-text-center tw-font-semibold ${companyIndex === 1 ? "tw-text-[#E1255B] tw-text-sm tw-leading-[18px]" : "tw-text-[#616096] tw-text-xs tw-leading-4"}`,
	},
	benefits: {
		wrapper: "tw-mt-4",
		list: "tw-flex tw-flex-col tw-gap-2 tw-justify-center",
		benefit:
			"tw-flex tw-text-[#22293A] tw-text-sm tw-gap-2 tw-leading-6 tw-text-pretty",
	},
	socialProof: {
		wrapper:
			"tw-relative tw-flex tw-mb-3.5 tw-rounded-lg tw-max-w-fit tw-bg-white tw-p-2 [filter:drop-shadow(0px_0px_1px_rgba(88,102,126,0.48))_drop-shadow(0px_0px_24px_rgba(88,102,126,0.10))] after:tw-absolute after:tw-right-[calc(50%-6px)] after:tw-w-2.5 after:tw-h-2.5 after:tw-translate-x-0 after:tw--translate-y-2/4 after:tw-rotate-45 after:tw-bg-white after:tw-overflow-hidden after:tw-pointer-events-none after:tw-top-full lg:tw-mt-3.5 lg:after:tw-top-0",
		contentWrapper:
			"tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-2",
		contentIcon: "tw-text-[#F68700] tw-text-[32px]",
		content:
			"tw-min-w-[102px] tw-max-w-[106px] tw-leading-4 tw-font-semibold tw-text-[11px] tw-text-left tw-text-[#22293A]",
	},
	visitLink: {
		wrapper:
			"tw-inline-flex tw-w-full tw-group tw-justify-center tw-items-center tw-p-2 tw-bg-[#359492] tw-rounded-lg tw-transition tw-duration-300 tw-gap-2 tw-cursor-pointer hover:tw-bg-[#246161] lg:tw-py-3",
		contentWrapper:
			"tw-text-white tw-font-semibold tw-leading-6 tw-text-sm lg:tw-text-base tw-text-center",
		iconClass:
			"tw-inline-flex tw-text-2xl tw-text-white tw-transition-all tw-duration-300 group-hover:tw-translate-x-1 lg:tw-hidden xl:tw-inline-flex",
	},
	reviewLink: {
		wrapper: css`
			${tw`mt-0 lg:mt-5 `}
			${companyIndex === 1 ? tw`mt-[80px] lg:mt-4` : tw``}
		`,
		text: css`
			${tw`text-[#359492] hover:underline text-base font-semibold`}
		`,
	},
});
